<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_1"
    data-name="Layer 1"
    viewBox="0 0 24 24"
    width="512"
    height="512"
  >
    <path
      d="M19.5,0H4.5C2.02,0,0,2.02,0,4.5v15c0,2.48,2.02,4.5,4.5,4.5h15c2.48,0,4.5-2.02,4.5-4.5V4.5c0-2.48-2.02-4.5-4.5-4.5Zm3.5,19.5c0,1.93-1.57,3.5-3.5,3.5H4.5c-1.93,0-3.5-1.57-3.5-3.5V4.5c0-1.93,1.57-3.5,3.5-3.5h15c1.93,0,3.5,1.57,3.5,3.5v15ZM5,9.5c0-.28,.22-.5,.5-.5H15.5c.28,0,.5,.22,.5,.5s-.22,.5-.5,.5H5.5c-.28,0-.5-.22-.5-.5Zm0-4c0-.28,.22-.5,.5-.5h7c.28,0,.5,.22,.5,.5s-.22,.5-.5,.5H5.5c-.28,0-.5-.22-.5-.5Zm14,8c0,.28-.22,.5-.5,.5H5.5c-.28,0-.5-.22-.5-.5s.22-.5,.5-.5h13c.28,0,.5,.22,.5,.5Zm-10,4c0,.28-.22,.5-.5,.5h-3c-.28,0-.5-.22-.5-.5s.22-.5,.5-.5h3c.28,0,.5,.22,.5,.5Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'reglementFacture'
};
</script>

<style lang="scss" scoped>
svg {
  fill: #704ad1;
  overflow: hidden;
  vertical-align: middle;
  transform: rotateZ(0deg);
  height: 25px;
  width: 25px;
}
</style>
